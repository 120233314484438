<template>
  <el-input v-model="modelValue" class="no-border-input" v-bind="$attrs"></el-input>
</template>

<script setup>
import {useVModel } from '@vueuse/core'
const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: ''
  }
})
const emit = defineEmits(['update:modelValue'])
const modelValue = useVModel(props, 'modelValue', emit)
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "InputWithNoBorder",
});
</script>

<style lang="scss" scoped>
.no-border-input{
  :deep(.el-input__wrapper){
    box-shadow: none;
    border: none;
    background: transparent;
  }
}
</style>
