<template>
  <div class="addOrder " v-loading="loadingTime">
    <div class="content">
      <div class="zh-add-title">{{ inStock?'其他入库':'其他出库' }}</div>
      <div class="queryBoxOne">
        <div class="btnBox">
           <div class="gyText ml_16" ><span class="red">*</span>{{ inStock ?'入库仓库':'出库仓库' }}</div>
          <el-select
            v-model="formData.stockId"
            placeholder="请选择"
            class="w240"
            :class="[isErr?'err':'']"
            clearable
            :disabled="isInStock"
            >
               <el-option v-for="item in sotckList" :key="item.stockId"  :label="item.stockName" :value="item.stockId"  />
          </el-select>
          <div class="gyText ml_16" ><span class="red">*</span>{{ inStock ?'入库类型':'出库类型' }}</div>
          <other-order-type-input v-model="formData.typeId" :order-type="orderType" class="mr_16 ml_16"></other-order-type-input>
          <other-order-type-update-btn :order-type="orderType"></other-order-type-update-btn>
        </div>
        <div class="queryItem">
        </div>
      </div>
      <div class="tabList" >
        <el-table :data="tableData" style="width: 100%" height="100%" class="zh-no-table-border"
                :row-class-name="tableRowClassName"
                :cell-style="{ border: 'none' }"
                :header-row-style="{ border: 'none' }">
          <el-table-column label="序号" width="100" align="center" fixed>
            <template #default="scope">
              <div class="table_text">
                {{scope.$index +
                  1
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column  align="center" fixed>
            <template #header>
              <div><span class="red">*</span> 产品名称</div>
            </template>
            <template #default="scope">
              <div class="table_text2 tableName160" >
                {{ scope.row.productName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="100" align="center" label="简称" show-overflow-tooltip>
            <template #default="scope">{{ scope.row.subName || '--'}}
            </template>
          </el-table-column>
          <el-table-column width="150" align="center" label="产品品牌" show-overflow-tooltip>
            <template #default="scope">
                  {{ scope.row.brandName || '--'}}
            </template>
          </el-table-column>
             <el-table-column
            align="center"
            label="规格/型号"
            show-overflow-tooltip
            class-name="table_text2"
          >
            <template #default="scope">{{ scope.row.modelName || '--'}}
            </template>
          </el-table-column>
          <el-table-column width="100" align="center" class-name="table_text2" label="产品单位" show-overflow-tooltip>
            <template #default="scope">
              <div v-html="getUnitNameLabel(orderInfo)"></div>
            </template>
          </el-table-column>
          <el-table-column width="160" align="center">
            <template #header>
              <div><span class="red">*</span>产品数量</div>
            </template>
            <template #default="{row,$index}">
              1
            </template>
          </el-table-column>
          <el-table-column width="160" align="center" >
            <template #header>
              <div><span class="red">*</span>单价(元)</div>
            </template>
            <template #default="scope">
              <el-input
              v-elInput
                v-number.fixed
                class="name tablInput w96"
                v-model="scope.row.price"
                placeholder="输入单价"
                maxlength="10"
                @blur="($event)=>{setPrice($event,scope.$index)}"
                v-if="scope.row.productId"
              />
            </template>
          </el-table-column>
          <el-table-column width="160" align="center" label="小计(元)" >
            <template #default="scope">
              {{ scope.row.totalPrice }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="remark">
        <div class="title">备注</div>
        <div class="input">
          <el-input
          v-elInput
            v-model="remark"
            type="textarea"
            placeholder="输入信息"
            class="textareaInput"
            input-style="border: none;box-shadow:none"
            resize="none"
            maxlength="100"
          />
        </div>
        <div class="btnBox">
          <el-button class="btn1 zh-btn w160" @click="cancel">取消</el-button>
          <el-button class="main btn1 w160" type="primary"  @click="confirm">提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {ElMessageBox} from "element-plus";
import {computed, defineProps, getCurrentInstance, onDeactivated, onMounted, reactive, ref, toRefs} from "vue";
import {stockList} from "@/utils/api/procurement/stock.js";
import {orderComplete} from "@/utils/api/order.js"
import {orderCompleteCheck} from "@/utils/api/rfid.js"
import {cloneDeep} from 'lodash';
import {checkErr} from '@/utils/tool/isErr.js'
import {inStockList} from "../config";
import {tableRowClassName} from "@/utils/tool.js";
import {getUnitNameLabel} from "@utils/tool/orderTools";
import OtherOrderTypeInput from "@components/form/otherOrderTypeInput/index.vue";
import OtherOrderTypeUpdateBtn from "@components/form/otherOrderTypeUpdateBtn/index.vue";
const loadingTime = ref(false)
let timeOutStatus = null
const { setErr ,isErr} = checkErr()
const { proxy } = getCurrentInstance();
const emit = defineEmits(["close"]);
const props = defineProps({
  inStock: {
    type: Boolean,
    default: false,
  },
  orderInfo:{
    type:Object,
    default:()=>{}
  },
  show:{
    type:Boolean,
    default:false
  }
});
const { inStock ,orderInfo, show } = toRefs(props);
const formData = reactive({
  searchList: [],
  thirdName: "",
  thirdId: "",
  stockId: "",
  typeId : 0 ,
});
//判断是否在库
const isInStock = computed(() => {
  return inStockList.includes(orderInfo.value.labelStatus);
});
const orderType = computed(() => {
  return inStock.value ? 2 : 6;
});
const remark = ref('')
const tableData = ref([]);
const sotckList = ref([]);
//获取仓库列表
const getSotckList = () => {
  stockList({
    status:true,
    all:false
  }).then((res) => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg);
      return;
    }
    sotckList.value = res.data.list || [];
  });
};
//设置总价
const setPrice = (e,index) => {
  tableData.value[index].price = e.target.value || 0;
  tableData.value[index].totalPrice = (+e.target.value || 0).toFixed(2)
};
//设置数量

//取消
const cancel = () => {
  ElMessageBox.confirm("信息未保存，是否确认取消！", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    customClass: "zh-message-box",
    }).then(() => {
    emit('close')
    })
};

//确认
const confirm = () => {
  if(!formData.stockId){
    setErr()
    return
  }
  let list = cloneDeep(tableData.value)
  list = list.map((item) => setLabel({...item}))
  orderComplete({
    stockId: formData.stockId,
    remark: remark.value,
    list: list,
    orderType:orderType.value,
    typeId:formData.typeId
  }).then((res) => {
    if (res.code === 0) {
      if (inStock.value) {
          loadingTime.value = true
          getIsOver()
        }else{
          proxy.$message.success('操作成功')
        emit('close')
        }
    } else if (res.code === 6000) {
      try {
        let str;
        let data = JSON.parse(res.msg)
        let label = data[0]?.labels?.[0] || {}
        if (label.remark) {
          str = label.remark
        } else {
          str = '该标签已被其它业务占用，请稍候重试'
        }
        proxy.$message.error(str)
      } catch (e) {
        proxy.$message.error(res.msg)
        throw new Error(e)
      }
    } else {
      proxy.$message.error(res.msg)
    }
  });
};


//设置label
const setLabel = (item)=>{
  let obj = cloneDeep(item)
  let labels = {}
  labels.labelId = obj.labelId
  labels.productId = obj.productId
  labels.modelId = obj.modelId
  labels.outTime = obj.outTime
  labels.createUserId = obj.createUserId
  labels.createUserName = obj.createUserName
  labels.createTime = obj.createTime
  labels.labelStatus = obj.labelStatus
  labels.serial = orderInfo.value.serial
  labels.rfid = orderInfo.value.rfid
  labels.scatteredCount = -1
  labels.price = orderInfo.value.price
  item.labels =[labels]
  // item.price = (parseFloat(obj.price) * 1000)
  item.price = +obj.price
  item.totalPrice = item.price
  item.realityTotalPrice = item.price
  item.realityCount = 1
  item.count = 1
  item.scattered = false
  return item
}
//设置列表信息
const getData =()=>{
  let obj = cloneDeep(orderInfo.value)
  obj.totalPrice = proxy.$filters.toYuan(obj.price,'元')
  obj.price = proxy.$filters.toYuan(obj.price,'元')
  obj.count = 1
  // obj.trueCount = obj.scatteredCount < 0 ? 1 : obj.scatteredCount
  tableData.value = [ obj ]
}

onMounted(()=>{
  formData.stockId = orderInfo.value.stockId || ''
  getSotckList()
  getData()
})


function getIsOver(){
  orderCompleteCheck({
    labelId:orderInfo.value.labelId,
  }).then(res=>{
    if (res.code !== 0) {
      loadingTime.value = false
      emit('close')
      return
    }
    if(res.data){
      loadingTime.value = false
      proxy.$message.success('操作成功')
      emit('close')
    }else{
      timeOutStatus = setTimeout(()=>{
        getIsOver()
      },1000)
    }
  })
}

onDeactivated(()=>{
  if(timeOutStatus) clearTimeout(timeOutStatus)
})

</script>

<script>
export default {
  name: "addOrder",
  components: {},
};
</script>

<style lang="scss" scoped>
@import "./add.scss";
:deep(.el-table__row) {
  cursor: pointer;
}
</style>
