<template>
  <div class="zh_title">
    <div class="zh_title_content" :style="styles">
      <slot name="prev"></slot>
      <span class="zh_title_content_text">{{ props.title }}</span>
    </div>
    <div class="zh_title_warn">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
  title:{
    type: String,
    default: ''
  },
  fontSize:{
    type: Number,
    default: 16
  }
})
const styles = computed(()=>({
  fontSize:props.fontSize + 'px'
}))
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "SubTitle",
});
</script>

<style lang="scss" scoped>
  .zh_title{
    display: flex;
    align-items: center;
    .zh_title_content{
      &_text{
        font-size: 16px;
      }
    }
    .zh_title_warn{
      margin-left: auto;
    }
  }
</style>
