import {ref , onMounted } from 'vue';
import {otherOrderTypeList} from '@/utils/api/order'
const otherOrderTypeOptions = ref([])

export default function useOtherOrderType(options = {}){
    function  setOtherOrderTypeOptions(list){
        // list.unshift({
        //     typeId:0,
        //     typeName:'其他入库',
        //     discardFlag:false,
        //     orderType:options.orderType
        // })
        otherOrderTypeOptions.value = list
    }
    const getOtherOrderTypeOptions =async ()=>{
        const {data,code,msg} = await otherOrderTypeList({
            orderType:options.orderType,
            discardFlag:options.discardFlag ?? -1
        })
        if(code !== 0){
            return
        }
        setOtherOrderTypeOptions(data?.list || [])
        return true
    }

    onMounted(async ()=>{
        if(options?.autoGet){
            await getOtherOrderTypeOptions()
        }
    })

    return{
        otherOrderTypeOptions,
        getOtherOrderTypeOptions
    }
}