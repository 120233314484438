<!--
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-07-31 10:49:46
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-09 16:51:27
 * @FilePath: /aurora-carton-web/src/views/product/labelManagement/index.vue
 * @Description: 标签管理页面
-->
<template>
  <div style="height: 100%">
    <div class="zh-wapper" v-if="!addShowInfo.show" v-loading="loading">
      <div class="zh-cotent">
        <div class="label-management">
          <form @submit.prevent="searchLabel" class="queryBox">
            <el-autocomplete
                v-model="formData.query"
                :debounce="500"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入标签序列号"
                @select="handleSelect"
                :trigger-on-focus="false"
                class="w200 mr_16"
                clearable
            />
            <el-button type="primary" native-type="submit" class="square">
              <el-icon><Search /></el-icon>
            </el-button>
          </form>
          <template v-if="orderInfo.labelId">
            <div class="goods-content mt_32">
              <div class="zh-title dp_f">
                <div class="title">序列号：{{ orderInfo.serial }}</div>
                <div
                  :class="[
                    'zh-label-text',
                    `label-status-${orderInfo.labelStatus}`,
                  ]"
                >
                  {{ orderInfo.labelStatusDesc }}
                </div>
              </div>
              <div
                class="goods-info mt_16"
                v-if="isShowBindGoods"
              >
                <div class="goods">
                  <div class="goods-pic">
                    <img :src="orderInfo.img" alt="位元灵感" />
                  </div>
                  <div class="goods-introduce">
                    <div class="goods-top">
                      <div class="goods-name">{{ orderInfo.productName }}</div>
                      <div class="zh-slash">/</div>
                      <div v-html="getUnitNameLabel(orderInfo)"></div>
                    </div>
                    <div class="goods-bottom">
                      <div class="single">
                        <span class="zh-key">产品品牌：</span>
                        <span class="zh-value">{{ orderInfo.brandName || '--' }}</span>
                      </div>
                      <div class="single">
                        <span class="zh-key">规格/型号：</span>
                        <span class="zh-value">{{ orderInfo.modelName }}</span>
                      </div>
                      <div class="single">
                        <span class="zh-key">产品条码：</span>
                        <more-list
                          :list="orderInfo.encoded"
                          v-if="orderInfo.encoded"
                          class="zh-value encoded-value"
                        ></more-list>
                        <span class="zh_goods_text" v-else>--</span>
                      </div>
                      <div class="single">
                        <LabelDesc :labelInfo="orderInfo" @change="changeDesc" ></LabelDesc>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="goods-order" v-if="isShowBindOrder">
                  <div
                    class="single"
                    v-if="
                      getThirdNames(orderInfo.orderType)
                    "
                  >
                    <span class="zh-key">{{ getThirdNames(orderInfo.orderType) }}：</span>
                    <span class="zh-value">{{ orderInfo.thirdName }}</span>
                  </div>
                  <div class="single" v-if="showInStock">
                    <span class="zh-key">入库仓库：</span>
                    <span class="zh-value">{{ orderInfo.stockName }}</span>
                  </div>
                  <div class="single" v-else>
                    <span class="zh-key">出库仓库：</span>
                    <span class="zh-value">{{ orderInfo.stockName }}</span>
                  </div>
                  <div
                    class="single order cur_p"
                    @click="clickOrderDetail(orderInfo)"
                    v-if="showOrderName(orderInfo)"
                  >
                    <span class="zh-key"
                      >{{ getOrderName(orderInfo.orderType) }}：</span
                    >
                    <span class="zh-value">{{ orderInfo.orderSn }}</span>
                  </div>
                  <div class="single">
                    <span class="zh-key">单价(元)：</span>
                    <span class="zh-value">{{
                      $filters.toYuan(orderInfo.price, "元")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="goods-info mt_16 zh_empty" v-else></div>
            </div>
            <div class="tabList">
                <!-- 列表 -->
                <el-table
                    :data="historyList"
                  style="width: 100%"
                  height="100%"
                    class="zh-no-table-border"
                  :row-class-name="tableRowClassName"
                  :header-row-style="{ border: 'none' }"
                  :span-method="arraySpanMethod"
                >
                  <el-table-column width="200" label="变更时间" align="center">
                    <template #default="scope">
                      <template v-if="!scope.row.historyText">
                        <div>{{ $filters.timeFormat(scope.row.updateTime, 1) }}</div>
                      </template>
                      <template v-else>
                        <div class="zh-table-title" style="font-weight: 600">{{ scope.row.historyText }}</div>
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    label="操作人"
                    width="150"
                    class-name="table_text"
                    show-overflow-tooltip
                  >
                    <template #default="scope">{{
                      scope.row.updateUserName
                    }}</template>
                  </el-table-column>
                  <el-table-column align="center" width="200" label="类型">
                    <template #default="scope">
                      <div class="dp_f" style="justify-content: center">
                        <div
                          :class="[
                            'zh-label-text',
                            `${
                              isInOrder(scope.row.orderType) ? 'in-stock' : 'out-stock'
                            }`,
                            `order-type-${scope.row.orderType}`,
                          ]"
                        >
                          {{ scope.row.operateTypeDesc }}
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="描述">
                    <template #default="scope">
                      <div
                        class="zh-label-desc h"
                        v-if="showOrders(scope.row)"
                        @click="clickOrderDetail(scope.row)"
                      >
                        <div class="single">
                          <span class="zh-key"
                            >{{
                              isInOrder(scope.row.orderType)
                                ? "入库仓库:"
                                : "出库仓库:"
                            }}：</span
                          >
                          <el-tooltip
                            :content="scope.row.stockName"
                            placement="top"
                            effect="light"
                          >
                            <span class="zh-value">{{
                              scope.row.stockName
                            }}</span>
                          </el-tooltip>
                        </div>
                        <div class="single">
                          <span class="zh-key"
                            >{{ getOrderName(scope.row.orderType) }}：</span
                          >
                          <el-tooltip
                            :content="scope.row.orderSn"
                            placement="top"
                            effect="light"
                          >
                            <span class="zh-value c_blue">{{
                              scope.row.orderSn
                            }}</span>
                          </el-tooltip>
                        </div>
                        <div class="single">
                          <span class="zh-key">单价（元）：</span>
                          <el-tooltip
                            :content="$filters.toYuan(scope.row.price, '元')"
                            placement="top"
                            effect="light"
                          >
                            <span class="zh-value">{{
                              $filters.toYuan(scope.row.price, "元")
                            }}</span>
                          </el-tooltip>
                        </div>
                        <div
                          class="single"
                          v-if="!getThirdNames(scope.row.orderType)"
                        ></div>
                        <div
                            class="single"
                            v-if="getThirdNames(scope.row.orderType)"
                        >
                          <span class="zh-key"
                          >{{ getThirdNames(scope.row.orderType) }}：</span
                          >
                          <el-tooltip
                              :content="scope.row.thirdName"
                              placement="top"
                              effect="light"
                          >
                            <span class="zh-value">{{
                                scope.row.thirdName
                              }}</span>
                          </el-tooltip>
                        </div>
                      </div>
                      <div
                          class="zh-label-desc h"
                          v-else-if="showCheckScattered(scope.row)"

                      >
                        <div class="single">
                          <span class="zh-key"
                          >盘点仓库：</span
                          >
                          <el-tooltip
                              :content="scope.row.stockName"
                              placement="top"
                              effect="light"
                          >
                            <span class="zh-value">{{
                                scope.row.stockName
                              }}</span>
                          </el-tooltip>
                        </div>
                        <div class="single">
                          <span class="zh-key"
                          >盘点前：</span
                          >
                          <el-tooltip
                              :content="scope.row.remark?.beforeScatteredCount + ''"
                              placement="top"
                              effect="light"
                          >
                            <span class="zh-value c_blue">{{
                                scope.row.remark?.beforeScatteredCount
                              }}</span>
                          </el-tooltip>
                        </div>
                        <div class="single">
                          <span class="zh-key">盘点后：</span>
                          <el-tooltip
                              :content="scope.row.remark?.afterScatteredCount + ''"
                              placement="top"
                              effect="light"
                          >
                            <span class="zh-value">{{ scope.row.remark?.afterScatteredCount }}</span>
                          </el-tooltip>
                        </div>
                        <div
                            class="single"
                            v-if="!getThirdNames(scope.row.orderType)"
                        ></div>
                        <div
                            class="single"
                            v-if="getThirdNames(scope.row.orderType)"
                        >
                          <span class="zh-key"
                          >{{ getThirdNames(scope.row.orderType) }}：</span
                          >
                          <el-tooltip
                              :content="scope.row.thirdName"
                              placement="top"
                              effect="light"
                          >
                            <span class="zh-value">{{
                                scope.row.thirdName
                              }}</span>
                          </el-tooltip>
                        </div>
                      </div>
                      <div
                          class="zh-label"
                          :class="scope.row.orderType === 16 && 'active'"
                        v-else-if="
                          (scope.row.orderType === 15 ||
                            scope.row.orderType === 16) &&
                          scope.row.remark
                        "
                      >
                        <div class="single" @click="showNewLabel(scope.row)">
                          <span class="zh-key"
                            >{{
                              scope.row.orderType === 16
                                  ? "原标签序列号"
                                  : "新标签序列号"
                            }}：</span
                          >
                          <span class="zh-value hover-bule">{{
                              scope.row.remark?.labelSerial
                            }}</span>
                        </div>
                        <div
                            class="zh-label-desc active chang-bind"
                            v-if="scope.row.orderType === 16 && scope.row.remark?.bindGoods"
                        >
                          <div class="single">
                          <span class="zh-key"
                          >商品名称：</span
                          >
                            <el-tooltip
                                :content="scope.row.remark?.bindGoods.productName"
                                placement="top"
                                effect="light"
                            >
                            <span class="zh-value ">{{
                                scope.row.remark?.bindGoods.productName
                              }}</span>
                            </el-tooltip>
                          </div>
                          <div class="single">
                          <span class="zh-key"
                          >产品品牌：</span
                          >
                            <el-tooltip
                                :content="scope.row.remark?.bindGoods.brandName || '--'"
                                placement="top"
                                effect="light"
                            >
                            <span class="zh-value ">{{
                                scope.row.remark?.bindGoods.brandName || '--'
                              }}</span>
                            </el-tooltip>
                          </div>
                          <div class="single">
                          <span class="zh-key"
                          >产品单位：</span
                          >
                            <el-tooltip
                                :content="scope.row.remark?.bindGoods.unitName"
                                placement="top"
                                effect="light"
                            >
                            <span class="zh-value">{{
                                scope.row.remark?.bindGoods.unitName
                              }}</span>
                            </el-tooltip>
                          </div>
                          <div class="single">
                          <span class="zh-key"
                          >规格/型号：</span
                          >
                            <el-tooltip
                                :content="scope.row.remark?.bindGoods.modelName"
                                placement="top"
                                effect="light"
                            >
                            <span class="zh-value">{{
                                scope.row.remark?.bindGoods.modelName
                              }}</span>
                            </el-tooltip>
                          </div>
                        </div>
                      </div>
                      <div
                          class="zh-label-desc active"
                          v-else-if="showGoods(scope.row)"
                      >
                        <div class="single">
                          <span class="zh-key"
                          >商品名称：</span
                          >
                          <el-tooltip
                              :content="scope.row.remark?.bindGoods.productName"
                              placement="top"
                              effect="light"
                          >
                            <span class="zh-value ">{{
                                scope.row.remark?.bindGoods.productName
                              }}</span>
                          </el-tooltip>
                        </div>
                        <div class="single">
                          <span class="zh-key"
                          >产品品牌：</span
                          >
                          <el-tooltip
                              :content="scope.row.remark?.bindGoods.brandName || '--'"
                              placement="top"
                              effect="light"
                          >
                            <span class="zh-value ">{{
                                scope.row.remark?.bindGoods.brandName || '--'
                              }}</span>
                          </el-tooltip>
                        </div>
                        <div class="single">
                          <span class="zh-key"
                          >产品单位：</span
                          >
                          <el-tooltip
                              :content="scope.row.remark?.bindGoods.unitName"
                              placement="top"
                              effect="light"
                          >
                            <span class="zh-value">{{
                                scope.row.remark?.bindGoods.unitName
                              }}</span>
                          </el-tooltip>
                        </div>
                        <div class="single">
                          <span class="zh-key"
                          >规格/型号：</span
                          >
                          <el-tooltip
                              :content="scope.row.remark?.bindGoods.modelName"
                              placement="top"
                              effect="light"
                          >
                            <span class="zh-value">{{
                                scope.row.remark?.bindGoods.modelName
                              }}</span>
                          </el-tooltip>
                        </div>
                      </div>
                      <div
                          class="zh-label-shelf-desc"
                          v-else-if="showShelf(scope.row) && scope.row.remark"
                      >
                        <div class="single">
                          <span class="zh-key"
                          >仓库名称：</span
                          >
                          <el-tooltip
                              :content="scope.row.stockName"
                              placement="top"
                              effect="light"
                          >
                            <span class="zh-value ">{{
                                scope.row.stockName
                              }}</span>
                          </el-tooltip>
                        </div>
                        <div class="single">
                          <span class="zh-key"
                          >货架编号：</span
                          >
                          <el-tooltip
                              :content="scope.row.remark?.childShelfName || '--'"
                              placement="top"
                              effect="light"
                          >
                            <span class="zh-value ">{{
                                scope.row.remark?.childShelfName || '--'
                              }}</span>
                          </el-tooltip>
                        </div>
                      </div>
                      <div class="zh-label-desc chang-bind" v-else-if="isChangeScattered(scope.row)">
                        <div class="single">
                           <span class="zh-key"
                           >原始单位：</span
                           >
                          <el-tooltip
                              :content="scope.row.remark?.beforeUnit+getChangeUnitName(scope.row).beforeCountUnit"
                              placement="top"
                              effect="light"
                              raw-content
                          >
                            <span class="zh-value "
                                  v-html="scope.row.remark?.beforeUnit+getChangeUnitName(scope.row).beforeCountUnit"></span>
                          </el-tooltip>
                        </div>
                        <div class="single">
                           <span class="zh-key"
                           >转化后单位：</span
                           >
                          <el-tooltip
                              :content="scope.row.remark?.afterUnit+getChangeUnitName(scope.row).afterCountUnit"
                              placement="top"
                              effect="light"
                              raw-content
                          >
                            <span class="zh-value "
                                  v-html="scope.row.remark?.afterUnit+getChangeUnitName(scope.row).afterCountUnit"></span>
                          </el-tooltip>
                        </div>
                        <div class="single">
                           <span class="zh-key"
                           >转化数量：</span
                           >
                          <el-tooltip
                              :content="scope.row.remark?.beforeCount + scope.row.remark?.beforeUnit+'→' + scope.row.remark?.afterCount + scope.row.remark?.afterUnit"
                              placement="top"
                              effect="light"
                          >
                            <span class="zh-value "
                                  v-html="scope.row.remark?.beforeCount + scope.row.remark?.beforeUnit+'→' + scope.row.remark?.afterCount + scope.row.remark?.afterUnit"></span>
                          </el-tooltip>
                        </div>
                      </div>
                      <div class="zh-label-desc chang-bind" v-else-if="isScatteredOutPacking(scope.row)">
                        <div class="single">
                           <span class="zh-key"
                           >原始数量：</span
                           >
                          <el-tooltip
                              :content="scope.row.remark?.beforeCount + ''"
                              placement="top"
                              effect="light"
                              raw-content
                          >
                            <span class="zh-value " v-html="scope.row.remark?.beforeCount"></span>
                          </el-tooltip>
                        </div>
                        <div class="single">
                           <span class="zh-key"
                           >{{ isScatteredOutPacking(scope.row).text }}数量：</span
                           >
                          <el-tooltip
                              :content="scope.row.remark?.wayCount + ''"
                              placement="top"
                              effect="light"
                              raw-content
                          >
                            <span class="zh-value " v-html="scope.row.remark?.wayCount"></span>
                          </el-tooltip>
                        </div>
                        <div class="single">
                           <span class="zh-key"
                           >{{ isScatteredOutPacking(scope.row).text }}后数量：</span
                           >
                          <el-tooltip
                              :content="scope.row.remark?.afterCount + ''"
                              placement="top"
                              effect="light"
                          >
                            <span class="zh-value " v-html="scope.row.remark?.afterCount"></span>
                          </el-tooltip>
                        </div>
                      </div>
                      <div class="zh-label-desc" v-else>
                        <div class="single">
                          <span class="zh-value">--</span>
                        </div>
                        <div class="single" v-if="scope.row.remark?.desc">
                          <span class="zh-value">{{
                              scope.row.remark?.desc
                            }}</span>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
            </div>
            <div class="mainPage">
              <el-button
                class="btn1 info_btn zh-btn"
                @click="addOrder(1)"
                v-if="!isInStock && orderInfo.labelStatus"
                >其他入库</el-button
              >
              <el-button
                class="btn1 info_btn zh-btn"
                @click="addOrder(2)"
                v-if="isInStock && orderInfo.labelStatus && isCanChange && !isScattered(orderInfo)"
                >其他出库</el-button
              >
              <el-button
                class="btn1 info_btn"
                @click="showTimeChange"
                v-if="(isInStock || isBindGoods) && isCanChange"
                type="primary"
                >效期修改</el-button
              >
              <el-button
                class="btn1 info_btn"
                @click="showChangeLabel"
                v-if="(isInStock || isBindGoods) && isCanChange "
                type="primary"
                >标签换绑</el-button
              >
              <el-button
                class="btn1 info_btn"
                @click="clickUnBind"
                v-if="isUnBind"
                type="primary"
                >标签解绑</el-button
              >
            </div>
          </template>
          <template v-else>
            <div class="zh_empty"></div>
          </template>
        </div>
      </div>
    </div>
    <add
      v-else
      :show="addShowInfo.show"
      :inStock="addShowInfo.inStock"
      @close="closeAdd"
      :orderInfo="orderInfo"
    ></add>
    <reset-rfid
      v-model="showResetRfid"
      :orderInfo="orderInfo"
      @close="closeChangeLabel"
    ></reset-rfid>
    <reset-time
      v-model="showResetTime"
      :orderInfo="orderInfo"
      @closeTime="closeChangeTime"
    ></reset-time>
    <OrderInfoDetail ref="orderShow" noMore></OrderInfoDetail>
    <OrderInfoAllocate
      v-model="orderInfoAllocateShow"
      noMore
    ></OrderInfoAllocate>
  </div>
</template>

<script setup>
import OrderInfoDetail from "@/views/order/components/orderDetailHistory.vue";
import OrderInfoAllocate from "@/views/inbound/allocate/components/inStockInfo.vue";
import Add from "./components/add.vue";
import {useStore} from "vuex";
import MoreList from "@/components/moreList/moreList.vue";
import ResetRfid from "./components/resetRfid.vue";
import ResetTime from "./components/resetTime.vue";
import {resetObj, tableRowClassName} from "@/utils/tool.js";
import {labelUnbindByLabel, searchRfidListHot} from "@/utils/api/rfid.js";
import {getCurrentInstance, onMounted, reactive, ref,} from "vue";
import {isScattered} from "./config";
import {ElMessageBox} from "element-plus";
import {isInOrder} from "@/utils/config";
import useRfidHistory from "@utils/hooks/useRfidHistory";
import useRfidOrderInfo from "@utils/hooks/useRfidOrderInfo";
import message from "@utils/tool/message";
import {getUnitNameLabel, orderOperateType} from '@/utils/tool/orderTools'
import LabelDesc from "@/components/rfidList/labelDesc";
import OtherOrderTypeUpdateBtn from "@components/form/otherOrderTypeUpdateBtn/index.vue";


const loading = ref(false);
const store = useStore();
const showResetRfid = ref(false);
const showResetTime = ref(false);
const orderShow = ref(null);
const orderInfoAllocateShow = ref(false);
const { proxy } = getCurrentInstance();
const {historyList, getRfidHistory} = useRfidHistory()
const {
  getStockListData,
  showOrders,
  showCheckScattered,
  showOrderName,
  orderInfo,
  getRfidOrderInfo,
  getThirdNames,
  getOrderName,
  isBindGoods,
  isUnBind,
  isCanChange,
  isInStock,
  isShowBindGoods,
  isShowBindOrder,
  showInStock,
  showGoods,
  isChangeScattered,
  getChangeUnitName,
  isScatteredOutPacking,
  showShelf
} = useRfidOrderInfo();
const changeDesc = (val)=>{
  orderInfo.labelDesc = val
}
const addShowInfo = reactive({
  show: false,
  inStock: false,
});
onMounted(() => {
  getStockListData()
})


let controller = null ;
const querySearchAsync =async (queryString, cb) => {
  controller && controller?.abort()
  controller = new AbortController()
  if(!queryString){
    return
  }
  const {data,msg,code} = await searchRfidListHot({serial:queryString})
  if(code !== 0 ){
    message.error(msg)
    return
  }
  data.list = data?.list || []
  cb(data.list.map(item=>({value:item})))
}

const handleSelect = (item)=>{
  searchLabel()
}


const formData = reactive({
  query: "",
});

//搜索标签信息
const searchLabel = () => {
  clearData();
  if (!formData.query) {
    proxy.$message.error("请输入标签序列号");
    return;
  }
  getData();
  getRfidHistory({
    serial: formData.query,
  })
};

const clearData = () => {
  resetObj(orderInfo);
  historyList.value = []
}


const arraySpanMethod =({
  row,
  column,
  rowIndex,
  columnIndex,
})=>{
  if (row.historyText) {
    if (columnIndex === 0) {
      return [1, 2]
    } else if (columnIndex === 1) {
      return [0, 0]
    }
  }
}

//标签换绑
const showChangeLabel = () => {
  showResetRfid.value = true;
};

//效期修改
const showTimeChange = () => {
  showResetTime.value = true;
};

//获取标签当前信息
const getData = async () => {
  loading.value = true;
  await getRfidOrderInfo({
    serial: formData.query,
  }, (data) => {
    orderInfo.serial = formData.query
    orderInfo.labelId = 1;
  })
  loading.value = false;
};


const clickUnBind =()=>{
  ElMessageBox.confirm('是否确认解绑该标签', "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        customClass: "zh-message-box",
      })
    .then(() => {
      //确认解绑该标签
      labelUnbindByLabel({
        labelId:orderInfo.labelId,
        productId:orderInfo.productId,
        modelId:orderInfo.modelId,
      }).then(res=>{
        if (res.code !== 0) {
          proxy.$message.error(res.msg)
          return;
        }
        proxy.$message.success('解绑成功')
        setQuery()
        searchLabel()
      })
    })
}



//其他出入库
const addOrder = (type = 1) => {
  //type: 1 入库  2 出库
  if (type === 1) {
    addShowInfo.show = true;
    addShowInfo.inStock = true;
  } else {
    addShowInfo.show = true;
    addShowInfo.inStock = false;
  }
};

const closeAdd = () => {
  addShowInfo.show = false;
  addShowInfo.inStock = true;
  setQuery()
  searchLabel();
};

const closeChangeLabel = () => {
  showResetRfid.value = false;
  setQuery()
  searchLabel();
};

const closeChangeTime = () => {
  showResetTime.value = false;
  setQuery()
  searchLabel();
};

function setQuery() {
  formData.query = orderInfo.serial
}
//打开订单详情弹窗
const clickOrderDetail = async (row) => {
  if (row.orderType > orderOperateType.LOSS_OUT && row.orderType !== orderOperateType.OPENING) {
    return;
  }
  store.commit("PURCHASE_ORDER_INFO", row);
  orderShow.value.openDetails()
}


const showNewLabel = (e) => {
  if (!e.remark?.labelSerial) {
    return;
  }
  if (formData.query === e.remark?.labelSerial) {
    return;
  }
  formData.query = e.remark.labelSerial;
  searchLabel();
};
defineExpose({
  formData,
});
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
