<template>
  <div v-bind="$attrs">
    <el-select :placeholder="placerholder.otherOrderType" v-model="modelValue">
      <template v-for="item in otherOrderTypeOptions" :key="item.typeId">
        <el-option v-if="!item.discardFlag" :label="item.typeName" :value="item.typeId"></el-option>
      </template>
    </el-select>
    <!--    <el-cascader  :options="otherOrderTypeOptions" v-model="modelValue" :props="oneSelectCascaderProp" :placeholder="placerholder.otherOrderType" clearable />-->
  </div>
</template>

<script setup>
import placerholder from '@/utils/config/placerholder'
import { useVModel} from '@vueuse/core'
import useOtherOrderType from "@utils/hooks/order/useOtherOrderType";

const props = defineProps({
  modelValue: {
    type: [Number, String],
    default: 0
  },
  orderType:{
    type:Number,
    default:0
  }
})
const { otherOrderTypeOptions } = useOtherOrderType({autoGet: true,orderType:props.orderType,discardFlag:0})
const emit = defineEmits(['update:modelValue', 'onChange'])
const modelValue = useVModel(props, 'modelValue', emit)

</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "OtherOrderTypeInput",
});
</script>

<style lang="scss" scoped>

</style>
