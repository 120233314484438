<!--
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-07-31 15:07:10
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-08 15:15:00
 * @FilePath: /aurora-carton-web/src/views/product/labelManagement/components/resetRfid.vue
 * @Description: 标签换绑
-->
<template>
  <div>
    <el-dialog
        v-bind="$attrs"
        title="标签换绑"
        width="480"
        class="zh-dialog "
        :close-on-click-modal="false"
        destroy-on-close
        align-center
        @open="openChangDia"
    >
      <div class="log-content reset-rfid">
        <!--        <div class="reset-single">-->
        <!--          <div class="reset-key"><span class="red">*</span>原标签</div>-->
        <!--          <div class="reset-value">换绑后，原标签作废不可再用！</div>-->
        <!--        </div>-->
        <div class="reset-single">
          <div class="reset-key"><span class="red">*</span>新标签序列号</div>
          <div class="reset-value">
            <search-serial  :class="[isErr?'err':'','w280']" v-model="formData.query" @on-change="checkLabel" @keydown.enter="checkLabel" :suffix-icon="Search"></search-serial>
          </div>
        </div>
        <div class="reset-single" v-show="newLabelInfo.labelId">
          <div class="reset-key">标签备注</div>
          <div class="reset-value">
            <el-input
                v-elInput
                :class="['w280']"
                :maxlength="labelRemarkMaxNum"
                placeholder="请输入备注,最多20位"
                v-model="newLabelInfo.labelDesc"
            ></el-input>
          </div>
        </div>
        <div class="reset-single animate__animated animate__zoomIn" v-show="newLabelInfo.labelId">
          <div class="reset-key">标签状态</div>
          <div class="reset-value">
            <div :class="newLabelInfo.labelStatus === 0?'normal':'errLabel'">{{ newLabelInfo.labelStatusDesc }}</div>
          </div>
        </div>
        <div class="reset-single animate__animated animate__zoomIn" v-show="newLabelInfo.labelId">
          <div class="reset-key">失效日期</div>
          <div class="reset-value" v-if="newLabelInfo.outTime">
            {{ $filters.timeFormat(newLabelInfo.outTime, 1) }}
          </div>
          <div class="reset-value" v-else>
            不关注效期
          </div>
        </div>
      </div>
      <template #footer>
        <el-button class="main btn1" type="primary" @click="handleOkChange" :disabled="!canConfirm"
        >确认绑定</el-button
        >
      </template>
    </el-dialog>

  </div>
</template>

<script setup>
import {computed, defineProps, getCurrentInstance, reactive, toRefs} from "vue";
import {checkErr} from '@/utils/tool/isErr.js';
import {labelChangeBind, labelCheck} from '@/utils/api/rfid.js'
import {Search} from '@element-plus/icons-vue'
import { labelRemarkMaxNum } from "@/utils/config/ruleConfig.js";
import SearchSerial from "@components/form/SearchSerial/index.vue";

const emit = defineEmits(['close'])
const { setErr ,isErr } = checkErr({
  msg:'请填写标签序列号'
})
const props = defineProps({
  orderInfo: {
    type: Object,
    default: {},
  },
});
const formData = reactive({
  query:'',
  remark:''
});

const newLabelInfo = reactive({
  labelStatus:0,
  labelStatusDesc:'',
  outTime:'',
  labelId:'',
  labelDesc:''
})
const changeQuery =()=>{
  newLabelInfo.labelStatus = ''
  newLabelInfo.labelStatusDesc = ''
  newLabelInfo.outTime = ''
  newLabelInfo.labelId =''
  newLabelInfo.labelDesc =''
}

const { orderInfo } = toRefs(props);
const { proxy } = getCurrentInstance();
//判断新标签
const checkLabel = ()=>{
  if(!formData.query){
    setErr()
    return
  }
  labelCheck({
    serial:formData.query,
    productId:orderInfo.value.productId,
  }).then(res=>{
    if(res.code !== 0){
      proxy.$message.error(res.msg)
      return;
    }
    newLabelInfo.labelStatus = res.data.labelStatus
    newLabelInfo.labelStatusDesc = res.data.labelStatusDesc
    newLabelInfo.outTime = res.data.outTime
    newLabelInfo.labelId = res.data.labelId
    newLabelInfo.labelDesc = ''
  })
}
const canConfirm = computed(()=>{
  return newLabelInfo.labelId && newLabelInfo.labelStatus === 0
})
const openChangDia = ()=>{
  formData.query = ''
  changeQuery()
}

const handleOkChange = ()=>{
  labelChangeBind({
    oldLabelId:orderInfo.value.labelId,
    productId:orderInfo.value.productId,
    modelId:orderInfo.value.modelId,
    labelId:newLabelInfo.labelId,
    outTime:orderInfo.value.outTime,
    labelDesc:newLabelInfo.labelDesc,
  }).then(res=>{
    if(res.code !== 0){
      proxy.$message.error(res.msg)
      return;
    }
    proxy.$message.success('换绑成功')
    proxy.$parent.formData.query = formData.query;
    emit('close')
  })
}
</script>

<style lang="scss" src="./index.scss">
</style>
