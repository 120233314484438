<template>
  <el-autocomplete
      v-bind="$attrs"
      v-model="modelValue"
      :debounce="300"
      :fetch-suggestions="querySearchAsync"
      placeholder="请输入标签序列号"
      @select="handleSelect"
      :trigger-on-focus="false"
      clearable
  />
</template>

<script setup>
import {useVModel} from '@vueuse/core'
import {searchRfidListHot} from "@/utils/api/rfid";
import message from "@utils/tool/message";

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: ''
  }
})
const emit = defineEmits(['update:modelValue', 'onChange'])

const modelValue = useVModel(props, 'modelValue', emit)

let controller = null;
const querySearchAsync = async (queryString, cb) => {
  controller && controller?.abort()
  controller = new AbortController()
  if (!queryString) {
    return
  }
  const {data, msg, code} = await searchRfidListHot({serial: queryString})
  if (code !== 0) {
    message.error(msg)
    return
  }
  data.list = data?.list || []
  cb(data.list.map(item => ({value: item})))
}

const handleSelect = () => {
  emit('onChange')
}

</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "SearchSerial",
});
</script>

<style lang="scss" scoped>

</style>
