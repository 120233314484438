<template>
  <div style="display: inline-block">
    <el-button type="primary" v-bind="$attrs" @click="openDia">编辑</el-button>
    <el-dialog
        v-model="showModal"
        :title="orderText + '类型'"
        width="920"
        class="other-order-type-dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        destroy-on-close
        @close="handleCloseAdd"
        align-center
    >
      <el-scrollbar :height="height-310 > 300 ? height-310 : 300 ">
        <div class="other-order-type-dialog-content" v-loading="loading">
          <sub-title :title="'其他' + orderText">
            <div class="other-order-type-dialog-desc">作废；{{orderText}}时，已作废类型不可再用！</div>
          </sub-title>
          <div class="list">
            <div class="list-item" v-for="item in otherOrderTypeOptions" :key="item.typeId">
              <div class="list-item-title" :class="[item.discardFlag?'disabled':'']">{{item.typeName}}</div>
              <div class="list-item-update list-item-btn" v-if="item.typeId" @click="handleUpdate(item)">{{ item.discardFlag ?'恢复':'作废' }}</div>
              <img src="@/assets/img/index/delete.png" v-if="item.typeId" alt="" class="list-item-del" @click="invalidType(item)"/>
            </div>
            <div class="list-item list-add" v-if="isAdd">
              <input-with-no-border v-model="formData.name" class="w200" :placeholder="placerholder.otherOrderTypesAdd" :maxlength="otherOrderTypeMaxNum"></input-with-no-border>
              <div class="list-item-btn">
                <span class="list-item-close " @click="handleCloseAdd">取消</span>
                <span class="list-item-save" @click="handleSaveType">保存</span>
              </div>
            </div>
            <div class="list-add" v-else>
              <span class="list-add-btn cur_p" @click="handleClickAdd">+添加</span>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script setup>
import {ref, reactive,computed} from "vue";
import useModal from "@utils/hooks/useModal";
import {useViewPort} from "@utils/tool/useVIewPort";
import SubTitle from "@components/subTitle/index.vue";
import InputWithNoBorder from "@components/form/InputWithNoBorder/index.vue";
import {otherOrderTypeMaxNum} from '@/utils/config/ruleConfig'
import placerholder from '@/utils/config/placerholder'
import {ElMessageBox} from "element-plus";
import message from "@utils/tool/message";
import {otherOrderTypeAdd,otherOrderTypeEdit , otherOrderTypeDel} from '@/utils/api/order'
import useOtherOrderType from "@utils/hooks/order/useOtherOrderType";

const loading = ref(false)
const props = defineProps({
  orderType:{
    type:Number,
    default:0
  }
})
const orderText = computed(()=>{
  return props.orderType === 2 ? '入库' : '出库'
})
const {otherOrderTypeOptions,getOtherOrderTypeOptions} = useOtherOrderType({orderType:props.orderType})
const isAdd = ref(false)
const formData = reactive({
  name:''
})
const {height} = useViewPort()
const {showModal,handleOpenModal} = useModal()
const openDia =async ()=>{
 await getOtherOrderTypeOptions()
  handleOpenModal()
}
//点击添加
const handleClickAdd = ()=>{
  isAdd.value = true
}
//取消添加
const handleCloseAdd = ()=>{
  isAdd.value = false
  resetFormData()
}

function resetFormData(){
  formData.name = ''
}


//删除类型
const invalidType = async (orderInfo) => {
  await  ElMessageBox.confirm("是否确认删除？", "提示", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    customClass: "zh-message-box",
  })
  const { code, msg} = await otherOrderTypeDel({
    orderType: props.orderType,
    typeId: orderInfo.typeId
  })
  if (code !== 0) {
    message.error(msg);
    return
  }
  message.success('删除成功')
  getOtherOrderTypeOptions()
}

//作废，恢复
const handleUpdate =async (orderInfo) => {
    loading.value = true
    const {data, code, msg} = await otherOrderTypeEdit({
      ...orderInfo,
      discardFlag: !orderInfo.discardFlag
    })
    if (code !== 0) {
      message.error(msg);
      return
    }
    const text = orderInfo.discardFlag ? '已恢复' : '已作废'
    message.success(text)
   await getOtherOrderTypeOptions()
    loading.value = false
}

//保存类型
const handleSaveType = async()=>{
  if(!formData.name){
    message.error('请输入入库类型')
    return
  }
  loading.value = true
  const {msg,code} = await otherOrderTypeAdd({
    typeName:formData.name,
    orderType:props.orderType
  })
  loading.value = false
  if(code !== 0){
    message.error(msg)
    return
  }
  message.success('添加成功')
  handleCloseAdd()
  await  getOtherOrderTypeOptions()
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "OtherOrderTypeUpdateBtn",
});
</script>

<style lang="scss" src="./index.scss" scoped>

</style>
