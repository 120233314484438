<!--
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-07-31 15:07:10
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-04 14:58:47
 * @FilePath: /aurora-carton-web/src/views/product/labelManagement/components/resetRfid.vue
 * @Description: 效期修改
-->
<template>
  <div>
    <el-dialog
        v-bind="$attrs"
        title="效期修改"
        width="480"
        class="zh-dialog "
        :close-on-click-modal="false"
        destroy-on-close
        align-center
        @open="openTimeDia"
    >
      <div class="log-content reset-time">
        <div class="reset-single mb_8">
          <div class="reset-key"><span class="red">*</span>关注效期</div>
          <div class="reset-value">
            <el-radio-group
                v-model="goodsInfo.followTime"
                style="font-size: 14px"
            >
              <el-radio :label="true">关注</el-radio>
              <el-radio :label="false">不关注</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div
            class="add_time_box reset-singles"
            :class="goodsInfo.followTime ? 'active' : ''"
        >
          <el-tabs v-model="goodsInfo.validityPeriodType" :stretch="true" class="demo-tabs"
                   @tab-click="changePeriodType">
            <el-tab-pane label="失效日期" :name="1">
            </el-tab-pane>
            <el-tab-pane label="保质期" :name="2">
            </el-tab-pane>
          </el-tabs>
          <div class="timeChooseBox" v-if="goodsInfo.validityPeriodType == 1">
            <span class="red">*</span> <span class="text">失效日期</span>
            <el-date-picker
                type="date"
                v-model="formData.outTime"
                placeholder="请选择时间"
                :class="[timeErr?'err':'']"
                @change="changeTime(1)"
            />
          </div>
          <div class="timeChooseBox" v-else>
            <span class="red">*</span> <span class="text2">生产时间</span>
            <el-date-picker
                class="w140"
                type="date"
                v-model="formData.time"
                placeholder="请选择时间"
                :class="[timeErr?'err':'']"
                @change="changeTime(2)"
            />
            <span class="red">*</span> <span class="text3">保质期</span>
            <span class="timeChoose" :class="[numErr?'err':'']">
            <el-input
            v-elInput
                v-number
                maxlength="3"
                class="timeChooseInput nbd"
                v-model="goodsInfo.qualityGuaranteePeriodNum"
                @change="changeTime(2)"
            ></el-input>
            <el-select
                placeholder="年"
                v-model="goodsInfo.qualityGuaranteePeriodType"
                class="nbd timeChooseInput2"
                @change="changeTime(2)"
            >
              <el-option label="天" :value="1" />
              <el-option label="月" :value="2" />
              <el-option label="年" :value="3" />
            </el-select>
          </span>
          </div>
        </div>
      </div>
      <template #footer>
        <el-button class="main btn1" type="primary" @click="handleOkAdd"
        >保存</el-button
        >
      </template>
    </el-dialog>

  </div>
</template>

<script setup>
import {defineProps, getCurrentInstance, reactive, ref, toRefs} from "vue";
import {checkErr} from '@/utils/tool/isErr.js';
import {cloneDeep} from "lodash";
import {labelChangeTime} from '@/utils/api/rfid.js'
import moment from "moment";

const emit = defineEmits(['closeTime'])
const timeErrObj = checkErr({
  msg:'请填写时间'
})
const timeErr = timeErrObj.isErr
const setTimeErr = timeErrObj.setErr
const numErrObj = checkErr({
  msg:'请填写时间'
})
const numErr = numErrObj.isErr
const setNumErr = numErrObj.setErr
const props = defineProps({
  orderInfo: {
    type: Object,
    default: {},
  },
});
const goodsInfo = ref({})
const formData = reactive({
  outTime: "",
  time:'',
  rfid: "",
});
const { orderInfo } = toRefs(props);
const { proxy } = getCurrentInstance();
const changePeriodType = ()=>{
  timeErr.value = false
}
const handleOkAdd = () => {
  if(goodsInfo.value.followTime){
    if(goodsInfo.value.validityPeriodType == 1){
    //关注到期日期
      if(!formData.outTime){
        setTimeErr()
        return
      }
    }else{
      //关注生产日期
      if(!formData.time){
        setTimeErr()
        return
      }
      if(!goodsInfo.value.qualityGuaranteePeriodNum){
        setNumErr()
        return
      }
    }
  }
  labelChangeTime({
    serial:goodsInfo.value.serial,
    outTime:goodsInfo.value.followTime ?new Date(formData.outTime).getTime() : 0,
    validityPeriodType:goodsInfo.value.validityPeriodType,
    qualityGuaranteePeriodNum:goodsInfo.value.qualityGuaranteePeriodNum,
    qualityGuaranteePeriodType:goodsInfo.value.qualityGuaranteePeriodType,
  }).then(res=>{
    if(res.code != 0){
      proxy.$message.error(res.msg)
      return;
    }
    proxy.$message.success('修改成功')
    emit('closeTime')
  })
};

const changeTime = (type)=>{
   //type 1到期时间 2生产日期
  if(!goodsInfo.value.qualityGuaranteePeriodNum){
    return
  }
  if(type == 1){
    let e = formData.outTime
    switch (goodsInfo.value.qualityGuaranteePeriodType) {
        case 3:
        formData.time = moment(e).subtract(goodsInfo.value.qualityGuaranteePeriodNum,'y')
          break;
        case 2:
        formData.time = moment(e).subtract(goodsInfo.value.qualityGuaranteePeriodNum,'M')
          break;
        case 1:
        formData.time = moment(e).subtract(goodsInfo.value.qualityGuaranteePeriodNum,'d')
          break;
        default:
          break;
      }
  }else{
    let e = formData.time
    switch (goodsInfo.value.qualityGuaranteePeriodType) {
        case 3:
        formData.outTime = moment(e).add(goodsInfo.value.qualityGuaranteePeriodNum,'y')
          break;
        case 2:
        formData.outTime = moment(e).add(goodsInfo.value.qualityGuaranteePeriodNum,'M')
          break;
        case 1:
        formData.outTime = moment(e).add(goodsInfo.value.qualityGuaranteePeriodNum,'d')
          break;
        default:
          break;
      }
  }
}

const openTimeDia = ()=>{
  goodsInfo.value = cloneDeep(orderInfo.value)
  if(!goodsInfo.value.validityPeriodType) goodsInfo.value.validityPeriodType = 1
  if(!goodsInfo.value.qualityGuaranteePeriodType) goodsInfo.value.qualityGuaranteePeriodType = 2
  if(goodsInfo.value.outTime){
    formData.outTime = goodsInfo.value.outTime
    changeTime(1)
  }
}
</script>

<style lang="scss" scoped src="./index.scss">
</style>
